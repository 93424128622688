<script>
export default {
  name: "cardAction",
  props: {
    title: {
      type: String,
      default: ""
    },
    item:{
      type:Object
    }
  },
  methods:{
    _headerAction(){
      this.$emit("onCardHeaderAction")
    }
  }
}
</script>

<template>
  <div class="card-item">
    <div class="shadow-card">
      <div class="d-flex justify-content-between align-items-center m-0 px-3 card-action-header">
        <span>{{ title }}</span>
        <span class="actionBtn d-flex gap-5" @click="_headerAction">
        <slot name="header-action-icon"></slot>
      </span>
      </div>
      <CRow class="py-3">
        <CCol>
          <slot></slot>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<style scoped>
.card-item .card-action-header {
  text-align: center;
  margin: 0;
  padding: 14px 7px;
  background-color: rgba(125,125,125,0.25);
  color: #222;
}

.actionBtn {
  border-radius: var(--border-radius);
  background-color: #b8b8b8;
  font-size: 12px;
  cursor: pointer;
  padding: 6px 12px;
}

.actionBtn:hover {
  background-color: #fff;
  color: red;
}
</style>
